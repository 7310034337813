import { fromJS, List } from 'immutable';
import Recipient from '../records/Recipient';
import { CHANNEL_SPECIFIC_OPAQUE_ID } from 'conversations-message-history/common-message-format/constants/deliveryIdentifierTypes';
export const buildContactRecipients = ({
  vid
}) => {
  return List([Recipient({
    deliveryIdentifier: fromJS([{
      type: CHANNEL_SPECIFIC_OPAQUE_ID,
      value: vid
    }])
  })]);
};