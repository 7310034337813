import { List, Record, Map as ImmutableMap } from 'immutable';
import get from 'transmute/get';
import DeliveryIdentifier from './DeliveryIdentifier';

function getIsDeliveryIdentifierList(props) {
  const deliveryIdentifier = get('deliveryIdentifier', props);
  return Boolean(deliveryIdentifier && !(deliveryIdentifier instanceof DeliveryIdentifier) && 'length' in deliveryIdentifier && !ImmutableMap.isMap(deliveryIdentifier));
}

function makeDeliveryIdentifiersFromList(props) {
  const deliveryIdentifierFromProps = get('deliveryIdentifier', props);
  const deliveryIdentifier = List(deliveryIdentifierFromProps.map(DeliveryIdentifier));
  const singleDeliveryIdentifier = DeliveryIdentifier(get('singleDeliveryIdentifier', props));
  return {
    deliveryIdentifier,
    singleDeliveryIdentifier
  };
}

function makeDeliveryIdentifiersFromSingleObject(props) {
  const deliveryIdentifierFromProps = get('deliveryIdentifier', props);
  const deliveryIdentifier = List([DeliveryIdentifier(deliveryIdentifierFromProps)]);
  const singleDeliveryIdentifier = DeliveryIdentifier(get('deliveryIdentifier', props));
  return {
    deliveryIdentifier,
    singleDeliveryIdentifier
  };
}

function toRecordProps(props) {
  const actorId = get('actorId', props);
  const recipientField = get('recipientField', props);
  const {
    deliveryIdentifier,
    singleDeliveryIdentifier
  } = getIsDeliveryIdentifierList(props) ? makeDeliveryIdentifiersFromList(props) : makeDeliveryIdentifiersFromSingleObject(props);
  return {
    actorId,
    recipientField,
    deliveryIdentifier,
    singleDeliveryIdentifier
  };
}

const Recipient = Record({
  actorId: '',
  singleDeliveryIdentifier: DeliveryIdentifier(),
  deliveryIdentifier: List(),
  recipientField: null
}, 'Recipient');
// eslint-disable-next-line import/no-anonymous-default-export
export default function (props) {
  const immutableProps = toRecordProps(props);
  return Recipient(immutableProps);
}