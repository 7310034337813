import Raven from 'raven-js';

const safelyCall = method => (...args) => {
  let returnValue;

  try {
    returnValue = Raven[method](...args);
  } catch (error) {
    setTimeout(() => {
      throw error;
    }, 0);
  }

  return returnValue;
};

export const captureException = safelyCall('captureException');
export const setExtraContext = safelyCall('setExtraContext');